import React from 'react';
import PageHeader from './PageHeader';

const judgesData = {
  2019: [
    {
      name: 'Александр Иванов',
      country: 'Россия',
      experience: '15 лет в области разработки программного обеспечения.',
      achievements: 'Руководитель разработки крупного банковского ПО, лауреат премии «Лучший IT-специалист года» в России.',
    },
    {
      name: 'Мария Петрова',
      country: 'Беларусь',
      experience: '10 лет в сфере кибербезопасности.',
      achievements: 'Руководитель отдела информационной безопасности в ведущей IT-компании, автор нескольких публикаций по кибербезопасности.',
    },
    {
      name: 'Дмитрий Коваленко',
      country: 'Украина',
      experience: '12 лет в области баз данных.',
      achievements: 'Архитектор баз данных в международной компании, создатель оптимизированных систем управления данными.',
    },
    {
      name: 'Айбек Ергешов',
      country: 'Казахстан',
      experience: '8 лет в разработке мобильных приложений.',
      achievements: 'Основатель успешного стартапа по разработке мобильных решений, победитель международных конкурсов.',
    },
    {
      name: 'Гюзель Ахмедова',
      country: 'Узбекистан',
      experience: '9 лет в IT-консалтинге.',
      achievements: 'Ведущий консультант по внедрению ERP-систем, разработчик инновационных методик консультирования.',
    },
  ],
  2020: [
    {
      name: 'Игорь Смирнов',
      country: 'Россия',
      experience: '17 лет в области разработки ПО.',
      achievements: 'Главный разработчик в крупной международной компании, победитель многочисленных хакатонов.',
    },
    {
      name: 'Анастасия Климова',
      country: 'Беларусь',
      experience: '11 лет в области кибербезопасности.',
      achievements: 'Руководитель группы исследователей киберугроз, признана экспертным сообществом за вклад в развитие методов защиты.',
    },
    {
      name: 'Игорь Тертичко',
      country: 'Украина',
      experience: '13 лет в работе IT технологий.',
      achievements: 'Директор по технологическому развитию и инновациям в компании EPAM Systems, участник международных конференций.',
    },
    {
      name: 'Болат Сарсенов',
      country: 'Казахстан',
      experience: '10 лет в разработке мобильных приложений.',
      achievements: 'Создатель популярного мобильного приложения, признанного лучшим в регионе.',
    },
    {
      name: 'Лейла Рахимова',
      country: 'Азербайджан',
      experience: '8 лет в IT-консалтинге.',
      achievements: 'Ведущий эксперт по цифровой трансформации, консультировала множество крупных компаний.',
    },
  ],
  2021: [
    {
      name: 'Сергей Михайлов',
      country: 'Россия',
      experience: '18 лет в разработке ПО.',
      achievements: 'Ведущий архитектор программных решений, лауреат государственных премий в области IT.',
    },
    {
      name: 'Ольга Лисовская',
      country: 'Беларусь',
      experience: '12 лет в сфере кибербезопасности.',
      achievements: 'Основатель и руководитель компании по кибербезопасности, автор научных трудов.',
    },
    {
      name: 'Александр Пашков',
      country: 'Украина',
      experience: '14 лет в работе с базами данных.',
      achievements: 'Ведущий разработчик в международной корпорации, эксперт по масштабированию баз данных.',
    },
    {
      name: 'Ермек Сулейменов',
      country: 'Казахстан',
      experience: '11 лет в разработке мобильных приложений.',
      achievements: 'Главный разработчик популярных мобильных игр, лауреат премий в области IT.',
    },
    {
      name: 'Наргиза Юсупова',
      country: 'Таджикистан',
      experience: '9 лет в IT-консалтинге.',
      achievements: 'Руководитель проектов по цифровизации, признана ведущим экспертом в регионе.',
    },
  ],
  2022: [
    {
      name: 'Николай Зайцев',
      country: 'Россия',
      experience: '19 лет в разработке ПО.',
      achievements: 'Ведущий инженер-программист, автор нескольких популярных библиотек и инструментов.',
    },
    {
      name: 'Екатерина Невская',
      country: 'Беларусь',
      experience: '13 лет в сфере кибербезопасности.',
      achievements: 'Консультант по информационной безопасности в крупных корпорациях, участник международных проектов.',
    },
    {
      name: 'Андрей Тарасов',
      country: 'Украина',
      experience: '15 лет в работе с базами данных.',
      achievements: 'Создатель распределенных систем управления данными, выступающий на мировых конференциях.',
    },
    {
      name: 'Данияр Нурпеисов',
      country: 'Казахстан',
      experience: '12 лет в разработке мобильных приложений.',
      achievements: 'Соучредитель успешного IT-стартапа, признанного на международном уровне.',
    },
    {
      name: 'Севиль Алиева',
      country: 'Азербайджан',
      experience: '10 лет в IT-консалтинге.',
      achievements: 'Ведущий консультант по цифровым решениям, автор методик повышения эффективности бизнеса.',
    },
  ],
  2023: [
    {
      name: 'Валерий Козлов',
      country: 'Россия',
      experience: '20 лет в разработке ПО.',
      achievements: 'Главный разработчик инновационных решений, лауреат международных премий.',
    },
    {
      name: 'Анна Брагина',
      country: 'Беларусь',
      experience: '14 лет в сфере кибербезопасности.',
      achievements: 'Руководитель исследовательских проектов по безопасности, автор патентов и публикаций.',
    },
    {
      name: 'Павел Кравченко',
      country: 'Украина',
      experience: '16 лет в работе с базами данных.',
      achievements: 'Ведущий эксперт по распределенным базам данных, признан международным сообществом.',
    },
    {
      name: 'Алмаз Алимжанов',
      country: 'Казахстан',
      experience: '13 лет в разработке мобильных приложений.',
      achievements: 'Разработчик награжденных приложений, известный в регионе и за его пределами.',
    },
    {
      name: 'Зарина Халилова',
      country: 'Узбекистан',
      experience: '11 лет в IT-консалтинге.',
      achievements: 'Ведущий специалист по внедрению IT-решений, автор успешных проектов цифровизации.',
    },
  ],
};

const Judges = () => {
  return (
    <>
      <PageHeader title="Судьи" />

      <div className="judges-page">
        <div className="judges-intro">
          <p>
            Судьи International IT Skills Competition — это элита в мире IT, и быть избранным в жюри данного чемпионата — огромная честь и престиж. Судьи играют ключевую роль в оценке участников, определении лучших проектов и установлении высоких стандартов соревнования. Каждый судья проходит тщательный отбор, чтобы обеспечить объективность и высокое качество оценивания.
          </p>
          <p className='pb-15'>
            Основными критериями для отбора судей являются:
          </p>
          <ul>
            <li><strong>Профессиональные знания и практический опыт:</strong> Судьи должны обладать глубокими знаниями в своей области и значительным практическим опытом.</li>
            <li><strong>Инновационность и вклад в развитие IT-индустрии:</strong> Судьи должны быть новаторами, внесшими существенный вклад в развитие технологий и отрасли в целом.</li>
            <li><strong>Объективность и непредвзятость:</strong> Способность объективно оценивать работу других специалистов, избегая предвзятости и субъективности.</li>
            <li><strong>Международный опыт:</strong> Судьи должны иметь опыт работы на международном уровне, чтобы учитывать мировые тенденции и стандарты в своих оценках.</li>
            <li><strong>Коммуникационные навыки:</strong> Умение четко и ясно выражать свои мысли, а также способность конструктивно критиковать и давать советы участникам.</li>
          </ul>
          <p>
            Минимальный опыт работы для судей составляет не менее пяти лет. Это необходимо для того, чтобы судьи имели достаточно знаний и практических навыков для объективной оценки участников. Опытные специалисты лучше понимают тонкости и сложности IT-проектов, могут оценить инновационные подходы и техническую реализацию на высшем уровне.
          </p>
          <p>
            Кроме того, судьями могут быть только специалисты с международным опытом. Это условие подчеркивает высокий статус чемпионата и обеспечивает разнообразие взглядов и подходов к оценке. Международный опыт позволяет судье оценивать проекты с учетом мировых тенденций и стандартов, что делает оценку более объективной и справедливой.
          </p>
          <p>
            Быть судьей International IT Skills Competition — это не только признание профессиональных заслуг, но и возможность внести свой вклад в развитие IT-сообщества, поддержать молодых талантов и стимулировать инновации. Судьи являются неотъемлемой частью успеха чемпионата, обеспечивая высокий уровень соревнований и содействуя развитию IT-индустрии на международном уровне.
          </p>
        </div>
        <div className="judges-list">
          {Object.keys(judgesData).map((year) => (
            <div key={year} className="judges-year">
              <h2 className="judges-year-title">Судьи <span className='highlight'>{year}</span> года</h2>
              {judgesData[year].map((judge, index) => (
                <div key={index} className="judge-card">
                  <h3 className="judge-name">{judge.name} <span className="judge-country">({judge.country})</span></h3>
                  <p><strong>Опыт работы:</strong> {judge.experience}</p>
                  <p><strong>Заслуги:</strong> {judge.achievements}</p>
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Judges;
