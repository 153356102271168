const winnersData = {
    2019: [
      {
        category: "Лучший разработчик программного обеспечения (Best Software Developer)",
        name: "Иван Смирнов",
        country: "Россия",
        project: "Инновационная система управления бизнес-процессами",
        achievements: "Иван обладает глубокими знаниями в области разработки ПО, специализируясь на создании сложных и высоконадежных систем. Его проект отличается инновационным подходом к автоматизации бизнес-процессов, улучшая их эффективность и производительность."
      },
      {
        category: "Лучший специалист по базам данных (Best Database Specialist)",
        name: "Игорь Тертичко",
        country: "Украина",
        project: "Оптимизация крупномасштабной базы данных для финансового сектора",
        achievements: "Игорь мастерски владеет методами проектирования и оптимизации баз данных. Его проект позволил значительно улучшить производительность и надёжность финансовой базы данных."
      },
      {
        category: "Лидер в области кибербезопасности (Cybersecurity Leader)",
        name: "Алексей Иванов",
        country: "Украина",
        project: "Система проактивной защиты от кибератак",
        achievements: "Алексей разработал уникальную систему проактивной защиты, которая эффективно предотвращает кибератаки. Его знания и опыт в кибербезопасности помогают защищать критически важные данные и инфраструктуры."
      },
      {
        category: "Лидер в разработке мобильных приложений (Mobile App Development Leader)",
        name: "Азамат Ергешов",
        country: "Казахстан",
        project: "Мобильное приложение для управления здоровьем",
        achievements: "Азамат создал инновационное приложение, которое помогает пользователям следить за своим здоровьем. Его навыки в разработке мобильных приложений и UX-дизайне сделали проект популярным и востребованным."
      },
      {
        category: "Лучший командный проект (Best Team Project)",
        teamName: "Team Innovators",
        country: "Узбекистан",
        project: "Платформа для онлайн-обучения с использованием ИИ",
        captain: "Наргиз Ахмедова",
        members: ["Тимур Саидов", "Алишер Каримов", "Дильбар Юсупова", "Марат Бекмуродов"],
        achievements: "Команда разработала платформу, которая использует ИИ для персонализации процесса обучения. Их проект демонстрирует отличное командное взаимодействие, инновационный подход и высокие технические навыки."
      }
    ],
    2020: [
      {
        category: "Лучший разработчик программного обеспечения (Best Software Developer)",
        name: "Володимир Топозли",
        country: "Украина",
        project: "Система управления логистическими процессами",
        achievements: "Олег обладает выдающимися навыками в разработке ПО для логистики. Его проект позволил значительно улучшить эффективность и прозрачность логистических операций."
      },
      {
        category: "Лучший специалист по базам данных (Best Database Specialist)",
        name: "Наталья Иванова",
        country: "Беларусь",
        project: "Система управления данными для медицинских учреждений",
        achievements: "Наталья специализируется на создании высоконадежных баз данных для медицины. Её проект помог улучшить управление данными в медицинских учреждениях, обеспечив их безопасность и доступность."
      },
      {
        category: "Лидер в области кибербезопасности (Cybersecurity Leader)",
        name: "Андрей Коваленко",
        country: "Украина",
        project: "Комплексная система защиты персональных данных",
        achievements: "Андрей разработал комплексную систему, защищающую персональные данные от утечек и взломов. Его проект получил признание за высокий уровень безопасности и инновационность."
      },
      {
        category: "Лидер в разработке мобильных приложений (Mobile App Development Leader)",
        name: "Бекжан Сарсенов",
        country: "Казахстан",
        project: "Приложение для управления финансами",
        achievements: "Бекжан создал удобное и функциональное приложение для управления личными финансами. Его проект отличается высоким уровнем UX и технической реализации."
      },
      {
        category: "Лучший командный проект (Best Team Project)",
        teamName: "Tech Wizards",
        country: "Азербайджан",
        project: "Система мониторинга экологической обстановки",
        captain: "Лейла Алиева",
        members: ["Рашид Гасанов", "Фарида Мустафаева", "Заур Мамедов", "Эльнур Керимов"],
        achievements: "Команда разработала систему, которая отслеживает и анализирует экологические данные в режиме реального времени. Их проект показывает отличные технические навыки и вклад в защиту окружающей среды."
      }
    ],
    2021: [
      {
        category: "Лучший разработчик программного обеспечения (Best Software Developer)",
        name: "Сергей Петров",
        country: "Россия",
        project: "Система автоматизации производства",
        achievements: "Сергей разработал передовую систему для автоматизации производственных процессов. Его проект выделяется высокой эффективностью и надежностью."
      },
      {
        category: "Лучший специалист по базам данных (Best Database Specialist)",
        name: "Виктория Романова",
        country: "Беларусь",
        project: "Система управления данными для образования",
        achievements: "Виктория специализируется на образовательных базах данных. Её проект улучшил управление учебными данными, обеспечив их безопасность и доступность."
      },
      {
        category: "Лидер в области кибербезопасности (Cybersecurity Leader)",
        name: "Денис Гончарук",
        country: "Украина",
        project: "Система защиты корпоративных сетей",
        achievements: "Денис разработал систему, которая эффективно защищает корпоративные сети от кибератак. Его проект получил признание за высокий уровень безопасности."
      },
      {
        category: "Лидер в разработке мобильных приложений (Mobile App Development Leader)",
        name: "Диана Сулейменова",
        country: "Казахстан",
        project: "Приложение для управления личными задачами",
        achievements: "Диана создала удобное и функциональное приложение для управления задачами. Её проект отличается высоким уровнем UX и технической реализации."
      },
      {
        category: "Лучший командный проект (Best Team Project)",
        teamName: "Data Innovators",
        country: "Узбекистан",
        project: "Система анализа больших данных для бизнеса",
        captain: "Фаррух Исмаилов",
        members: ["Лола Юлдашева", "Тимур Абдуллаев", "Алишер Насыров", "Дильноза Хайдарова"],
        achievements: "Команда разработала систему для анализа больших данных, которая помогает бизнесам принимать обоснованные решения. Их проект показывает отличные технические навыки и инновационный подход."
      }
    ],
    2022: [
      {
        category: "Лучший разработчик программного обеспечения (Best Software Developer)",
        name: "Михаил Волков",
        country: "Россия",
        project: "Система управления энергоресурсами",
        achievements: "Михаил разработал систему для управления энергоресурсами, которая улучшает эффективность использования энергии. Его проект выделяется высокой надежностью и инновационным подходом."
      },
      {
        category: "Лучший специалист по базам данных (Best Database Specialist)",
        name: "Анна Кузнецова",
        country: "Беларусь",
        project: "Система управления данными для розничной торговли",
        achievements: "Анна специализируется на базах данных для розничной торговли. Её проект помог улучшить управление товарооборотом и складскими запасами."
      },
      {
        category: "Лидер в области кибербезопасности (Cybersecurity Leader)",
        name: "Евгений Зайцев",
        country: "Украина",
        project: "Система защиты от фишинг-атак",
        achievements: "Евгений разработал систему, которая эффективно защищает пользователей от фишинг-атак. Его проект получил признание за высокий уровень безопасности и удобство использования."
      },
      {
        category: "Лидер в разработке мобильных приложений (Mobile App Development Leader)",
        name: "Алибек Байжанов",
        country: "Казахстан",
        project: "Приложение для фитнеса и здорового образа жизни",
        achievements: "Алибек создал приложение, которое помогает пользователям следить за своим здоровьем и физической формой. Его проект отличается высоким уровнем UX и технической реализации."
      },
      {
        category: "Лучший командный проект (Best Team Project)",
        teamName: "Future Tech",
        country: "Азербайджан",
        project: "Платформа для управления умным домом",
        captain: "Рашид Алиев",
        members: ["Заур Гасанов", "Наргиза Мамедова", "Эльнур Рзаев", "Фарида Салимова"],
        achievements: "Команда разработала платформу для управления умным домом, которая позволяет пользователям контролировать и автоматизировать бытовые процессы. Их проект показывает отличные технические навыки и инновационный подход."
      }
    ],
    2023: [
      {
        category: "Лучший разработчик программного обеспечения (Best Software Developer)",
        name: "Дмитрий Орлов",
        country: "Россия",
        project: "Система управления производственными процессами",
        achievements: "Дмитрий разработал систему, которая помогает оптимизировать производственные процессы, повышая их эффективность и снижая затраты. Его проект выделяется высокой надежностью и инновационным подходом."
      },
      {
        category: "Лучший специалист по базам данных (Best Database Specialist)",
        name: "Ольга Васильева",
        country: "Беларусь",
        project: "Система управления данными для банковской сферы",
        achievements: "Ольга специализируется на базах данных для банковской сферы. Её проект помог улучшить управление финансовыми данными, обеспечив их безопасность и доступность."
      },
      {
        category: "Лидер в области кибербезопасности (Cybersecurity Leader)",
        name: "Виктор Григорьев",
        country: "Украина",
        project: "Система защиты от вирусов и вредоносного ПО",
        achievements: "Виктор разработал систему, которая эффективно защищает пользователей от вирусов и вредоносного ПО. Его проект получил признание за высокий уровень безопасности и удобство использования."
      },
      {
        category: "Лидер в разработке мобильных приложений (Mobile App Development Leader)",
        name: "Алмат Садыков",
        country: "Казахстан",
        project: "Приложение для управления финансами",
        achievements: "Алмат создал удобное и функциональное приложение для управления личными финансами. Его проект отличается высоким уровнем UX и технической реализации."
      },
      {
        category: "Лучший командный проект (Best Team Project)",
        teamName: "Smart Solutions",
        country: "Узбекистан",
        project: "Платформа для анализа данных с использованием ИИ",
        captain: "Дилшод Рахимов",
        members: ["Лола Исмаилова", "Тимур Юлдашев", "Алибек Каримов", "Фаррух Абдуллаев"],
        achievements: "Команда разработала платформу для анализа данных с использованием ИИ, которая помогает бизнесам принимать обоснованные решения. Их проект показывает отличные технические навыки и инновационный подход."
      }
    ],
    2024: [
      {
        category: "Лучший разработчик программного обеспечения (Best Software Developer)",
        name: "Максим Соколов",
        country: "Россия",
        project: "Система управления цепочками поставок",
        achievements: "Максим разработал систему для управления цепочками поставок, которая улучшает их эффективность и прозрачность. Его проект выделяется высокой надежностью и инновационным подходом."
      },
      {
        category: "Лучший специалист по базам данных (Best Database Specialist)",
        name: "Светлана Новикова",
        country: "Беларусь",
        project: "Система управления данными для здравоохранения",
        achievements: "Светлана специализируется на базах данных для здравоохранения. Её проект помог улучшить управление медицинскими данными, обеспечив их безопасность и доступность."
      },
      {
        category: "Лидер в области кибербезопасности (Cybersecurity Leader)",
        name: "Павел Морозов",
        country: "Украина",
        project: "Система защиты от угроз в реальном времени",
        achievements: "Павел разработал систему, которая эффективно защищает пользователей от угроз в реальном времени. Его проект получил признание за высокий уровень безопасности и удобство использования."
      },
      {
        category: "Лидер в разработке мобильных приложений (Mobile App Development Leader)",
        name: "Ержан Тулегенов",
        country: "Казахстан",
        project: "Приложение для управления здоровьем",
        achievements: "Ержан создал инновационное приложение, которое помогает пользователям следить за своим здоровьем. Его проект отличается высоким уровнем UX и технической реализации."
      },
      {
        category: "Лучший командный проект (Best Team Project)",
        teamName: "AI Innovators",
        country: "Азербайджан",
        project: "Платформа для прогнозирования и анализа данных",
        captain: "Эмиль Гасанов",
        members: ["Лейла Алиева", "Наргиза Гусейнова", "Вугар Исмаилов", "Заур Мамедов"],
        achievements: "Команда разработала платформу для прогнозирования и анализа данных с использованием ИИ, которая помогает бизнесам принимать обоснованные решения. Их проект показывает отличные технические навыки и инновационный подход."
      }
    ]
  };

  export default winnersData;