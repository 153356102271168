import React from 'react';


const Contacts = () => {
  return (
    <div>
        FFFFFFFF
    </div>
  );
};

export default Contacts;