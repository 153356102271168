import React from 'react';
import PageHeader from './PageHeader'; 

const Position = () => {
  return (
    <>
    <PageHeader title="ПОЛОЖЕНИЕ" />
      
    <div className="position-page">
        <div className="position-content-section">
          <div className="position-content">
            <h2 className="position-title">1. Общие положения</h2>
            <p className="position-text">1.1. Международный Чемпионат по IT-Навыкам (далее — Чемпионат) проводится ежегодно среди IT-специалистов из всех стран СНГ.</p>
            <p className="position-text">1.2. Организатором Чемпионата является Международная ассоциация IT-экспертов (GAITE).</p>
            <p className="position-text">1.3. Основной целью Чемпионата является выявление и поддержка лучших IT-специалистов, стимулирование их профессионального роста и обмен опытом.</p>
            <p className="position-text">1.4 Чемпионат проводится ежегодно 1 по 17 апреля, начиная с 2019 года. Прием заявок осуществляется ежегодно с 15 по 31 марта.</p>
            
            <h2 className="position-title">2. Участники Чемпионата</h2>
            <p className="position-text">2.1. В Чемпионате могут принимать участие IT-специалисты со всех стран СНГ с опытом работы не менее 5 лет.</p>
            <p className="position-text">2.2. Участники должны обладать высоким уровнем профессионализма и международным опытом.</p>
            <p className="position-text">2.3. Для участия в Чемпионате необходимо пройти предварительный отбор и зарегистрироваться на официальном сайте.</p>
            
            <h2 className="position-title">3. Номинации Чемпионата</h2>
            <p className="position-text">3.1. Чемпионат включает следующие номинации:</p>
            <ul className="position-list">
              <li>Лучший разработчик программного обеспечения (Best Software Developer)</li>
              <li>Лучший специалист по базам данных (Best Database Specialist)</li>
              <li>Лидер в области кибербезопасности (Cybersecurity Leader)</li>
              <li>Лидер в разработке мобильных приложений (Mobile App Development Leader)</li>
              <li>Лучший командный проект (Best Team Project)</li>
            </ul>

            <h2 className="position-title">4. Критерии оценки</h2>
            <p className="position-text">4.1. Оценка участников проводится по следующим критериям:</p>
            <h3 className="position-subtitle">Для номинации "Лучший разработчик программного обеспечения":</h3>
            <ul className="position-list">
              <li>Качество кода</li>
              <li>Инновационность решений</li>
              <li>Производительность</li>
              <li>Масштабируемость</li>
              <li>Документация</li>
            </ul>
            <h3 className="position-subtitle">Для номинации "Лучший специалист по базам данных":</h3>
            <ul className="position-list">
              <li>Проектирование базы данных</li>
              <li>Оптимизация запросов</li>
              <li>Безопасность данных</li>
              <li>Масштабируемость базы данных</li>
              <li>Документирование</li>
            </ul>
            <h3 className="position-subtitle">Для номинации "Лидер в области кибербезопасности":</h3>
            <ul className="position-list">
              <li>Углубленные знания</li>
              <li>Практическое применение</li>
              <li>Инновации в безопасности</li>
              <li>Комплексный подход</li>
              <li>Обучение и осведомленность</li>
            </ul>
            <h3 className="position-subtitle">Для номинации "Лидер в разработке мобильных приложений":</h3>
            <ul className="position-list">
              <li>Пользовательский опыт (UX)</li>
              <li>Техническая реализация</li>
              <li>Инновационность</li>
              <li>Кроссплатформенность</li>
              <li>Отзывчивость и адаптивность</li>
            </ul>
            <h3 className="position-subtitle">Для номинации "Лучший командный проект":</h3>
            <ul className="position-list">
              <li>Слаженность работы команды</li>
              <li>Качество выполнения проекта</li>
              <li>Инновационность проекта</li>
              <li>Влияние и значимость</li>
              <li>Документация и презентация</li>
            </ul>

            <h2 className="position-title">5. Жюри</h2>
            <p className="position-text">5.1. Жюри Чемпионата состоит из пяти высококвалифицированных специалистов с международным опытом.</p>
            <p className="position-text">5.2. Судьи отбираются по следующим критериям:</p>
            <ul className="position-list">
              <li>Профессиональные знания и практический опыт</li>
              <li>Инновационность и вклад в развитие IT-индустрии</li>
              <li>Объективность и непредвзятость</li>
              <li>Международный опыт</li>
              <li>Коммуникационные навыки</li>
            </ul>
            <p className="position-text">5.3. Судьи обязаны объективно оценивать работу участников и принимать решения на основе установленных критериев.</p>
            
            <h2 className="position-title">6. Проведение Чемпионата</h2>
            <p className="position-text">6.1. Чемпионат проводится в несколько этапов, включая предварительный отбор, основные соревнования и финал.</p>
            <p className="position-text">6.2. Основные соревнования включают выполнение участниками заданий, соответствующих их номинации.</p>
            <p className="position-text">6.3. Финал включает презентацию проектов и их защиту перед жюри.</p>
            
            <h2 className="position-title">7. Награждение победителей</h2>
            <p className="position-text">7.1. Победители в каждой номинации получают дипломы, медали и ценные призы.</p>
            <p className="position-text">7.2. Лучшие проекты и достижения участников будут опубликованы на официальном сайте Чемпионата и в СМИ.</p>
            <p className="position-text">7.3. Победители получают возможность участия в международных конференциях и других профессиональных мероприятиях.</p>
            
            <h2 className="position-title">8. Заключительные положения</h2>
            <p className="position-text">8.1. Все участники Чемпионата обязаны соблюдать правила и этические нормы, установленные Организатором.</p>
            <p className="position-text">8.2. Организатор оставляет за собой право вносить изменения в настоящее Положение и информировать об этом участников.</p>
            <p className="position-text">8.3. Положение вступает в силу с момента его публикации на официальном сайте Чемпионата.</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Position;